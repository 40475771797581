import Axios, { AxiosRequestConfig } from 'axios';

const config: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_BIGQUERY_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
};

const BigQueryClient = Axios.create(config);
export { BigQueryClient };
