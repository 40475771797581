import Axios, { AxiosRequestConfig, } from 'axios';

const config: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
};

const BackendClient = Axios.create(config);
export { BackendClient };
