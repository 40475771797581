import Vue from 'vue';
import Router, { Route } from 'vue-router';
import Time from './views/Time.vue';
import Targets from './components/admin/Targets.vue';
import Totals from './components/admin/Totals.vue';
import Permissions from './components/admin/Permissions.vue';
import NotFound from './views/NotFound.vue';
import WeekDetails from './views/WeekDetails.vue';
import store from './store';
import TimeOffRequest from './views/TimeOffRequest.vue';
import { PageNames } from '@/misc';
import { UserActions } from '@/store/modules/user';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: { name: PageNames.Time, params: { id: store.getters.harvestId } }
    },
    {
      path: '/users/:id',
      name: PageNames.Time,
      props: (route: Route): any => {
        return {id: Number(route.params.id)};
      },
      component: Time,
      meta: {
        analytics: {
          pageviewTemplate(route: any) {
            return {
              title: 'User Details',
              page: '/users',
              location: '/details',
            };
          },
        },
      },
    },
    {
      path: '/totals',
      name: PageNames.Totals,
      component: Totals,
      meta: {
        analytics: {
          pageviewTemplate(route: any) {
            return {
              title: 'Users',
              page: '/users',
              location: '/users',
            };
          },
        },
      },
    },
    {
      path: '/targets',
      name: PageNames.Targets,
      component: Targets,
      meta: {
        analytics: {
          pageviewTemplate(route: any) {
            return {
              title: 'Targets',
              page: '/targets',
              location: '/targets',
            };
          },
        },
      },
    },
    {
      path: '/week/:id/:year-:month-:day',
      name: PageNames.WeekDetails,
      props: (route: Route): any => {
        return {
          id: Number(route.params.id),
          year: Number(route.params.year),
          month: Number(route.params.month),
          day: Number(route.params.day),
        };
      },
      component: WeekDetails,
      meta: {
        analytics: {
          pageviewTemplate(route: any) {
            return {
              title: 'WeekDetails',
              page: '/week',
              location: '/week',
            };
          },
        },
      },
    },
    {
      path: '*',
      name: PageNames.NotFound,
      component: NotFound,
    },
    {
      path: '/permissions',
      name: PageNames.Permissions,
      component: Permissions,
      meta: {
        analytics: {
          pageviewTemplate(route: any) {
            return {
              title: 'Permissions',
              page: '/permissions',
              location: '/permissions',
            };
          },
        },
      },
    },
    {
      path: '/bamboo/time-off/:timeOffId',
      name: PageNames.TimeOffRequest,
      props: (route: Route): any => {
        return { timeOffId: Number(route.params.timeOffId) };
      },
      component: TimeOffRequest,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch(UserActions.LOAD_CLAIMS);
  next();
});

export default router;
