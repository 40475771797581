import { BackendClient } from '.';
import { DateTime } from 'luxon';
import { TargetMonthData, TimeTarget } from '@/types/backend/target-types';
import { ClientInfo, MLTeamMember } from '@/types/backend/client-types';
import { SlackUser } from '@/types/backend/slack-types';
import { FirebaseUser, LastBlogPost } from '@/types/backend';
import { ProjectDetailReport } from '@/types/backend/project-types';
import { UsersReport } from '@/types/backend/time-types';
import { JiraBoard } from '@/types/backend/jira-types';
import { LatticeFeedbackList } from '@/types/backend/lattice-types';
import { User, UserIds } from '@/types/backend/user-types';
import { UserTimeBreakdown, UserTimeBreakdownWeek, UserTimeBreakdownRange } from '@/types/backend/user-time-types';

export default class BackendRequests {

  public static async getSlackUsers(): Promise<SlackUser[]> {
    try {
      const res = await BackendClient.get('/slack-users');
      return res.data.slack_users;
    } catch (error) {
      return [];
    }
  }

  public static async getActiveUsersWorkingAfterTheirHireDate(): Promise<User[]> {
    try {
      const res = await BackendClient.get('/lr-users');
      return res.data.users;
    } catch (error) {
      return [];
    }
  }

  public static async getUsers(): Promise<FirebaseUser[]> {
    try {
      const res = await BackendClient.get('/users');
      return res.data.users;
    } catch (error) {
      return [];
    }
  }

  public static async getUserByHarvestId(harvestId: number): Promise<User> {
    const result = await BackendClient.get(`/user/${harvestId.toString()}`);
    return result.data.user;
  }

  public static async updateSubmitToggle(id: string, state: boolean): Promise<boolean> {
    const res = await BackendClient.patch('/slack-users/month-toggle/' + id, { state });
    if (res.status === 200) {
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  }

  public static async getUsersForTargets(): Promise<User[]> {
    const result = await BackendClient.get('/targets');

    return result.data.users;
  }

  public static async getTargetsForUserByHarvestId(harvestId: number): Promise<TimeTarget[]> {
    const result = await BackendClient.get(`/user/${harvestId.toString()}/targets`);

    return result.data.targets;
  }

  public static async createTargetForUserByHarvestId(harvestId: number, timeTarget: TimeTarget): Promise<TimeTarget> {
    const result = await BackendClient.post(`/user/${harvestId.toString()}/target`, timeTarget);

    return result.data.target;
  }

  public static async deleteTarget(harvestId: number, effectiveDate: string): Promise<any> {
    await BackendClient.delete(`/user/${harvestId}/targets/${effectiveDate}`);
  }

  public static async getCostRate(): Promise<number> {
    try {
      const res = await BackendClient.get('/cost-rate');
      return res.data.cost_rate;
    } catch (error) {
      return 0;
    }
  }

  public static async setCostRate(rate: number): Promise<boolean> {
    try {
      const res = await BackendClient.post(
        '/cost-rate',
        { cost_rate: rate },
        { headers: { 'Content-Type': 'application/json' } },
      );
      if (!!res.data.updated_at) {
        return true;
      }
    } catch (error) {
      // error will return false
    }
    return false;
  }

  public static async getMonthlyUsersReport(
    reportId: string,
  ): Promise<UsersReport | null> {
    try {
      const res = await BackendClient.get('/users_reports/' + reportId);
      return res.data;
    } catch (error) {
      return null;
    }
  }

  public static getWeekTarget(
    data: any,
    weekStart: DateTime,
  ): number {
    let monthStart: DateTime = weekStart.startOf('month');

    if (monthStart.weekday !== 7) {
      monthStart = monthStart.plus({ days: 7 - monthStart.weekday });
    }

    const monthTargets: any = data.targets.find(
      (monthTarget: TargetMonthData) =>
        monthTarget.month_start_date === monthStart.toFormat('MM/dd/yyyy'),
    );

    if (monthTargets) {
      const weekTarget: any = monthTargets.weeks.find(
        (target: any) =>
          target.start_date === weekStart.toFormat('MM/dd/yyyy'),
      );
      if (weekTarget) {
        return weekTarget.target;
      }
    }
    return data.target;
  }

  public static async getTargetTrend(): Promise<any> {
    const res = await BackendClient.get('/trends/targets');
    return res.data;
  }

  public static async getMLTeam(teamId: string): Promise<MLTeamMember[]> {
    try {
      const res = await BackendClient.get('/ml-team/' + teamId);
      return res.data.team;
    } catch (error) {
      return [];
    }
  }

  public static async getJiraBoardData(projectCode: string): Promise<JiraBoard> {
    try {
      const res = await BackendClient.get('/jira-board/' + projectCode);
      return res.data;
    } catch (error) {
      return {
        number: 0,
        remaining_days: 0,
        to_do: [],
        in_progress: [],
        in_review: [],
        done: [],
      };
    }
  }

  public static async getUserTimeBreakdown(harvestId: number): Promise<UserTimeBreakdown> {
    const res = await BackendClient.get('/user-time-breakdown/' + harvestId.toString());
    if (res.status === 200) {
      return res.data;
    }

    return {
      harvest_id: '',
      months: [],
    };
  }

  public static async getUserTimeBreakdownWeek(harvestId: number, startOfWeek: DateTime): Promise<UserTimeBreakdownWeek | null> {
    const weekStart = startOfWeek.year.toString() + '-' + startOfWeek.month.toString() + '-' + startOfWeek.day.toString();

    const res = await BackendClient.get('/user-time-breakdown-week/' + harvestId.toString() + '/' + weekStart);
    if (res.status === 200) {
      return res.data;
    }

    return null;
  }

  public static async getUserTimeBreakdownRange(harvestId: number, startOfRange: DateTime, endOfRange: DateTime): Promise<UserTimeBreakdownRange> {
    const result = await BackendClient.get(`user/${harvestId}/weeks_summary`, {
      params: {
        start: startOfRange.toFormat('yyyy-MM-dd'),
        end: endOfRange.toFormat('yyyy-MM-dd'),
        today: DateTime.now().toFormat('yyyy-MM-dd'),
      },
    });

    return result.data;
  }
}
