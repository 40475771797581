import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import VueApexCharts from 'vue-apexcharts';
import VueGtag from 'vue-gtag';

import 'vue-material-design-icons/styles.css';
import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue';
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue';
import Check from 'vue-material-design-icons/Check.vue';
import Close from 'vue-material-design-icons/Close.vue';
import MagnifyingGlass from 'vue-material-design-icons/Magnify.vue';
import Download from 'vue-material-design-icons/Download.vue';

Vue.component('MdiArrowLeft', ArrowLeft);
Vue.component('MdiArrowRight', ArrowRight);
Vue.component('MdiCheck', Check);
Vue.component('MdiClose', Close);
Vue.component('MdiMagnifyingGlass', MagnifyingGlass);
Vue.component('MdiFileDownload', Download);

import {
  faArrowUp,
  faArrowDown,
  faSpinner,
  faCheck,
  faPlus,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faSignInAlt,
  faSignOutAlt,
  faSyncAlt,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faClock,
  faSquare,
  faUsers,
  faChartArea,
  faTrash,
  faBullseye,
  faTh,
  faPencilAlt,
  faSave,
  faUserClock,
  faBriefcase,
  faBirthdayCake,
  faTimes,
  faExternalLinkAlt,
  faQuestionCircle,
  faExclamationTriangle,
  faComment,
  faList,
  faCalendarAlt,
  faCalculator,
  faSlash,
  faCheckCircle,
  faMinusCircle,
  faAddressBook,
  faIdCardAlt,
  faCamera,
  faTimesCircle,
  faPlusCircle,
  faCircle,
  faRunning,
  faEllipsisV,
  faArrowsAltH,
  faExpandArrowsAlt,
  faUsersCog,
  faSearch,
  fas,
  faArrowLeft,
  faArrowRight,
  faBan,
  faQuestion,
  faFileDownload
} from '@fortawesome/free-solid-svg-icons';
import { faPaperclip } from '@fortawesome/fontawesome-pro-regular';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DateTime } from 'luxon';
import { setupInterceptors } from '@//setupInterceptors';

library.add(
  faArrowUp as IconDefinition,
  faArrowDown as IconDefinition,
  faSpinner as IconDefinition,
  faCheck as IconDefinition,
  faPlus as IconDefinition,
  faAngleDown as IconDefinition,
  faAngleUp as IconDefinition as IconDefinition,
  faAngleLeft as IconDefinition as IconDefinition,
  faAngleRight as IconDefinition as IconDefinition,
  faSignInAlt as IconDefinition as IconDefinition,
  faSignOutAlt as IconDefinition as IconDefinition,
  faSyncAlt as IconDefinition as IconDefinition,
  faChevronRight as IconDefinition as IconDefinition,
  faChevronLeft as IconDefinition as IconDefinition,
  faChevronDown as IconDefinition as IconDefinition,
  faChevronUp as IconDefinition as IconDefinition,
  faClock as IconDefinition as IconDefinition,
  faSquare as IconDefinition as IconDefinition,
  faUsers as IconDefinition as IconDefinition,
  faChartArea as IconDefinition,
  faTrash as IconDefinition,
  faBullseye as IconDefinition,
  faTh as IconDefinition,
  faPencilAlt as IconDefinition,
  faSave as IconDefinition,
  faUserClock as IconDefinition,
  faBriefcase as IconDefinition,
  faBirthdayCake as IconDefinition,
  faTimes as IconDefinition,
  faExternalLinkAlt as IconDefinition,
  faPaperclip as IconDefinition,
  faQuestionCircle as IconDefinition,
  faExclamationTriangle as IconDefinition,
  faComment as IconDefinition,
  faList as IconDefinition,
  faCalendarAlt as IconDefinition,
  faCalculator as IconDefinition,
  faSlash as IconDefinition,
  faCheckCircle as IconDefinition,
  faMinusCircle as IconDefinition,
  faAddressBook as IconDefinition,
  faIdCardAlt as IconDefinition,
  faCamera as IconDefinition,
  faTimesCircle as IconDefinition,
  faPlusCircle as IconDefinition,
  faCircle as IconDefinition,
  faRunning as IconDefinition,
  faEllipsisV as IconDefinition,
  faArrowsAltH as IconDefinition,
  faExpandArrowsAlt as IconDefinition,
  faUsersCog as IconDefinition,
  faSearch as IconDefinition,
  faArrowLeft as IconDefinition,
  faArrowRight as IconDefinition,
  faBan as IconDefinition,
  faQuestion as IconDefinition,
  faFileDownload as IconDefinition,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('ApexChart', VueApexCharts);

Vue.use(VueApexCharts);

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  enabled: process.env.NODE_ENV == 'production',
  config: { id: 'G-TLWRP5JW9X' }
}, router);

// Global filters
/**
 * Takes an ISO string and formats it according to the format string provided.
 */
Vue.filter('formatIsoString', (value: string, format: string) => {
  if (!!value) {
    return DateTime.fromISO(value).toFormat(format);
  }
  return '';
});

/**
 * Formats a number value as negative or positive dollars.
 */
Vue.filter('formatDollars', (value: number) => {
  if (value < 0) { return '-$' + Math.abs(value); }
  return '$' + value;
});

/**
 * Formats a number into an HH:mm time
 */
Vue.filter('formatTime', (value: number) => {
  if (value) {
    const minutes = Math.ceil((value % 1 * 60) % 60);
    if (minutes < 10) {
      return Math.floor(value) + ':0' + minutes;
    }
    return Math.floor(value) + ':' + minutes;
  } else {
    return '0:00';
  }
});

/**
 * Truncates the string to the specified length passed in
 */
Vue.filter('truncateString', (value: string, length: number) => {
  if (value) {
    return value.substring(0, length);
  }
});

/**
 * Setup all the request/response interceptors for our 4 axios instances
 *
 * We have to do this early enough in the process to be useful (i.e. we can start making network requests)
 * but we also need access to the store - so here it goes
 */
setupInterceptors();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
