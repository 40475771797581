import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '@/store';
import router from '@/router';
import { BackendClient } from '@/network/backend';
import { UserActions } from '@/store/modules/user';
import { ForecastClient } from '@/network/forecast';
import { BigQueryClient } from '@/network/bigquery';

export function setupInterceptors(): void {
  setBackendResponseInterceptors(BackendClient);
  setRequestInterceptors(BackendClient);

  setForecastResponseInterceptors(ForecastClient);
  setRequestInterceptors(ForecastClient);

  setBigQueryResponseInterceptors(BigQueryClient);
  setRequestInterceptors(BigQueryClient);
}

function setRequestInterceptors(client: AxiosInstance): void {
  function onFullfilled<T>(request: AxiosRequestConfig): AxiosRequestConfig {
    if (request.headers === undefined) {
      request.headers = {};
    }
    request.headers.Authorization = 'Bearer ' + store.getters.accessToken;
    return request;
  }

  function onRejected(error: any) {
    return Promise.reject(error);
  }

  client.interceptors.request.use(onFullfilled, onRejected);
}

function setBackendResponseInterceptors(client: AxiosInstance): void {
  function onFullfilled<T>(response: AxiosResponse<T>): AxiosResponse<T> {
    return response;
  }

  async function onRejected(error: any) {
    const originalConfig = error.config;

    if (!error.response || error.response.status === 401) {
      if (error.response.data.description === 'Expired token') {
        // Access token was expired so try to get a new one using the refresh token.
        // Retry the previous request if we got a new token
        originalConfig._retry = true;
        await store.dispatch(UserActions.REFRESH_AUTHORIZATION);
        return client(originalConfig);
      } else {
        store.dispatch('logout');
      }
    } else if (!!error.response && error.response.status === 400) {
      router.push('/404');
    }
    return Promise.reject(error);
  }

  client.interceptors.response.use(onFullfilled, onRejected);
}

function setForecastResponseInterceptors(client: AxiosInstance): void {
  function onFullfilled<T>(response: AxiosResponse<T>): AxiosResponse<T> {
    return response;
  }

  async function onRejected(error: any) {
    if (!error.response || error.response.status === 401) {
      // TODO: Why is the webapp still making requests ot the forecast API directly? And why would a 401 log the webapp out?
      store.dispatch(UserActions.LOGOUT);
    } else if (!!error.response && error.response.status === 400) {
      await router.push('/404');
    }
    return Promise.reject(error);
  }

  client.interceptors.response.use(onFullfilled, onRejected);
}

function setBigQueryResponseInterceptors(client: AxiosInstance): void {
  function onFullfilled<T>(response: AxiosResponse<T>): AxiosResponse<T> {
    return response;
  }

  function onRejected(error: any): Promise<any> {
    if (!!error.response && error.response.status === 400) {
      router.push('/404');
    }
    return Promise.reject(error);
  }

  client.interceptors.response.use(onFullfilled, onRejected);
}
