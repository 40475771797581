import {
  ForecastProject,
  ForecastRemainingBudgetedHours,
} from '@/types/forecast-types';
import { BackendClient } from '../backend';

export default class ForecastRequests {

  /**
   * Retrieves projects from Forecast
   */
  public static async projects(): Promise<ForecastProject[]> {
    const res = await BackendClient.get('/forecast/projects');
    if (res.status === 200) {
      return res.data.projects;
    }
    return [];
  }

  /**
   * Returns data related to the comparison between budget and hours
   */
  public static async remainingBudgetHours(): Promise<ForecastRemainingBudgetedHours[]> {
    const res = await BackendClient.get('/forecast/remaining-budgeted-hours');
    return res.data.remaining_budgeted_hours;
  }
}
