import { BackendClient } from '@/network/backend/index';
import { TokenResponse } from '@/types/token-response';
import { AxiosResponse } from 'axios';

export default class AuthRequests {
  /**
   * Authenticate user with the backend using
   * their Google token ID
   * @param tokenId Google user's token ID
   */
  public static async authorize(idToken: string): Promise<TokenResponse> {
    const response = await BackendClient.post('/google/auth/token', {
      id_token: idToken,
    });
    return response.data;
  }

  /**
   * Request a new access token using the refresh token
   */
  public static async refreshAccessToken(refreshToken: string): Promise<TokenResponse> {
    const response = await BackendClient.post('/google/auth/refresh', {
      refresh: refreshToken,
    });
    return response.data;
  }
}