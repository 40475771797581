/*
Takes in an array of strings and converts it into delimited line fit for a CSV
Input:
  ['Total', 'Count', 'Funky "Column with quotes', 'Funky ,,,Column with commas']
Output:
  "Total","Count","Funky ""Column with quotes","Funky ,,,Column with commas"
*/
export function escapeStringArrayForCSV(arr: readonly string[], delimiter=','): string {
  return `"${arr
    .map(item => item.replace('"', '""'))
    .join(`"${delimiter}"`)}"`;
}

export function downloadCSV(filename: string, header: string, body: string): void {
  const csva = document.createElement('a');
  const data = encodeURI(`${header}\n${body}\n`);
  csva.href = `data:text/csv;charset=utf-8,${data}`;
  csva.download = filename;
  csva.click();
}
