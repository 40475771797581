// tslint:disable:member-ordering
import { DateTime } from 'luxon';
import {
  HarvestUser,
  Client,
  TimeEntry,
  ProjectAssignment,
  Expense,
  Project,
  SLIndividual,
  Invoice,
} from '@/types/harvest-types';
import { BackendClient } from '../backend';

export default class HarvestRequests {

  public static async submitList(submitId: number): Promise<SLIndividual[]> {
    const res = await BackendClient.get('/submit-list/' + submitId.toString());
    if (res.status === 200) {
      return Promise.resolve(res.data.individuals);
    }
    throw Error(res.statusText);
  }

  /**
   * Retreives all clients
   * If user does not have access to other clients, returns empty array
   */
  public static async clients(): Promise<Client[]> {
    try {
      const res = await BackendClient.get('/harvest/clients');
      if (res.status === 200) {
        return res.data.clients;
      } else {
        return [];
      }
    } catch (er) {
      return [];
    }
  }

  /**
   * Retrieves specific client by their id
   * @param id Client account id
   */
  public static async client(id: number): Promise<Client> {
    const res = await BackendClient.get('/harvest/clients/' + id.toString());
    if (res.status === 200) {
      return Promise.resolve(res.data);
    }
    throw Error(res.statusText);
  }

  /**
   * Retreives all users (if current user has access)
   * If user does not have access to other users, returns empty array
   */
  public static async users(): Promise<HarvestUser[]> {
    try {
      const res = await BackendClient.get('/harvest/users');
      if (res.status === 200) {
        return res.data.users;
      } else {
        return [];
      }
    } catch (er) {
      return [];
    }
  }

  /**
   * Retrieves specific user by their id
   * @param id Harvest account id
   */
  public static async user(id: number | string): Promise<HarvestUser> {
    const result = await BackendClient.get('/harvest/users/' + id.toString());
    if (result.status === 200) {
      return Promise.resolve(result.data);
    }
    throw Error(result.statusText);
  }

  /**
   * Returns time entries given a range of DateTime's and user id
   * @param harvestId Harvest account id
   * @param startDate beginning date for time entries (null to get all entries)
   * @param endDate ending date for time entries (null to get all entries)
   */
  public static async getEntriesInRange(
    harvestId: number,
    startDate: DateTime | null = null,
    endDate: DateTime | null = null,
  ): Promise<TimeEntry[]> {
    const cutOffYear: DateTime = DateTime.local().minus({ year: 1 });
    const data: any = {
      from: !!startDate ? startDate.toFormat('yyyy-MM-dd') : cutOffYear.toFormat('yyyy-MM-dd'),
      to: endDate?.toFormat('yyyy-MM-dd') ?? null,
    };

    const result = await BackendClient.get(`/user/${harvestId}/time-entries/range`, {
      params: data,
    });
    return result.data.time_entries;
  }

  public static async updateEntry(harvestId: number, entry: TimeEntry) {
    try {
      await BackendClient.patch(`/user/${harvestId}/time-entries/${entry.id}`, {
        hours: entry.hours,
        notes: entry.notes,
      });
    } catch (error) {
      // tslint:disable-next-line
      console.error('Couldn\'t update entry');
    }
  }

  public static async updateSubmitHours(user: SLIndividual, month: number): Promise<boolean> {
    const res =  await BackendClient.patch('/submit-list/' + month.toString(), user);
    if (res.status === 200) {
      return Promise.resolve(true);
    }
    return Promise.resolve(false);

  }

  /**
   * Creates a time entry given relevant data
   * @param harvestId id of the user to create the time entry for
   * @param projectId id for project to add time entry to
   * @param taskId id for task to add time entry to
   * @param spentDate date for time entry
   * @param amtHours amount of time
   * @param comments notes for entry
   */
  public static async postTimeEntry(
    harvestId: number,
    projectId: number,
    taskId: number,
    spentDate: string,
    amtHours: number,
    comments: string,
  ) {
    try {
      await BackendClient.post(
        `/user/${harvestId}/time-entries`,
        {
          project_id: projectId,
          task_id: taskId,
          spent_date: spentDate,
          hours: amtHours,
          notes: comments,
        },
      );
    } catch (error) {
      throw error;
    }
  }

  /**
   * Deletes a time entry from Harvest
   * @param harvestId id for the user linked to the time entry
   * @param entryId id of the entry to delete
   */
  public static async deleteEntry(harvestId: number, entryId: number) {
    try {
      await BackendClient.delete(`/user/${harvestId}/time-entries/${entryId}`);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Retrieves all projects current user has access to
   */
  public static async getUserProjects(harvestId: string): Promise<ProjectAssignment[]> {
    let projects = [] as ProjectAssignment[];
    try {
      const res = await BackendClient.get(`/harvest/users/${harvestId}/project-assignments`);
      projects = res.data.project_assignments;
    } catch (error) {
      // console.error(error.message);
    }
    return projects;
  }

  /**
   * Retrieves all projects
   * Note: user must be project manager or admin to successfully retrieve projects
   */
  public static async getAllProjects(): Promise<Project[]> {
    let projects = [] as Project[];
    try {
      const res = await BackendClient.get('/harvest/projects');
      projects = res.data.projects;
    } catch {
      // console.error(error.message);
    }
    return projects;
  }

  public static async getProjectById(projectId: number): Promise<Project> {
    let projects = [] as Project[];
    let project: Project[] = [];

    try {
      const res = await BackendClient.get('/harvest/projects');
      projects = res.data.projects;
      project = res.data.projects.filter((p: Project) => {
        if (p.id === projectId) {
          return p;
        }
      });
    } catch {
      // console.error(error.message);
    }

    return project[0];
  }

  public static async getAllProjectsByClient(clientId: number): Promise<Project[]> {
    let projects: Project[] = [];
    try {
      const res = await BackendClient.get('/harvest/projects?client_id=' + clientId.toString());
      projects = res.data.projects;
    } catch {
      // console.error(error.message);
    }
    return projects;
  }

  /**
   * Retrieves all invoices for a project
   */
  public static async getInvoicesByProjectId(projectId: number): Promise<Invoice[]> {
    let invoices = [] as Invoice[];
    try {
      const res = await BackendClient.get('/harvest/invoices?project_id=' + projectId.toString());
      invoices = res.data.invoices;
    } catch (error) {
      // console.error(error);
    }
    return invoices;
  }

  public static async getExpenses(): Promise<Expense[]> {
    let expenses = [] as Expense[];
    try {
      const res = await BackendClient.get('/harvest/expenses');
      expenses = res.data.expenses;
    } catch (error) {
      // log error here
    }
    return expenses;
  }

  public static async getExpensesById(projectId: number): Promise<Expense[]> {
    let expenses = [] as Expense[];
    try {
      const res = await BackendClient.get('/harvest/expenses', {
        params: { project_id: projectId },
      });
      expenses = res.data.expenses;
    } catch (error) {
      // log error here
    }
    return expenses;
  }

  public static async getAmountOfTimeEntries(harvestId: number, amount: number): Promise<TimeEntry[]> {
    const res = await BackendClient.get(`/user/${harvestId}/time-entries`, {
      params: {
        per_page: amount,
      },
    });
    return res.data.time_entries;
  }

  public static async restartTimer(harvestId: number, entryId: number) {
    try {
      await BackendClient.patch(`/user/${harvestId}/time-entries/${entryId}/restart`);
    } catch (error) {
      // tslint:disable-next-line
      console.error('Couldn\'t update entry');
    }
  }

  public static async stopTimer(harvestId: number, entryId: number) {
    try {
      await BackendClient.patch(`/user/${harvestId}/time-entries/${entryId}/stop`);
    } catch (error) {
      // tslint:disable-next-line
      console.error('Couldn\'t update entry');
    }
  }
}
