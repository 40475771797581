import { Module } from 'vuex';
import { RootState } from '@/store';
import jwt_decode from 'jwt-decode';
import HarvestRequests from '../../network/harvest/requests';
import AuthRequests from '@/network/backend/auth';
import BackendRequests from '@/network/backend/requests';
import { User } from '@/types/backend/user-types';
import { RequestStatus } from '@/types/request-status';

export const enum UserActions {
  PERFORM_AUTHORIZATION = 'PERFORM_AUTHORIZATION',
  GET_USER_BY_HARVEST_ID = 'GET_USER_BY_HARVEST_ID',
  LOAD_CLAIMS = 'LOAD_CLAIMS',
  LOAD_HARVEST_USER = 'LOAD_HARVEST_USER',
  LOGOUT = 'LOGOUT',
  REFRESH_AUTHORIZATION = 'REFRESH_AUTHORIZATION',
}

export const enum UserMutations {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  CLEAR_STATE = 'CLEAR_STATE',
  CLAIMS = 'CLAIMS',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  AUTH_STATE = 'AUTH_STATE',
}

interface Claims {
  bambooId: string | null;
  email: string | null;
  harvestId: string | null;
  isAdmin: boolean;
  isPracticeLead: boolean;
  latticeId: string | null;
  name: string | null;
  slackId: string | null;
}

interface UserState {
  // Authentication stuff
  authStatus: RequestStatus;

  // Authorization stuff
  accessToken: string | null;
  refreshToken: string | null;

  // Claims
  claims: Claims | null;
}

const unpackClaims = (token: string | null): Claims | null => {
  if (token === null) {
    return null;
  }

  const result: any =  jwt_decode(token);
  return {
    bambooId: result.bamboo_id,
    email: result.user_email,
    harvestId: result.harvest_id,
    isAdmin: result.is_admin ,
    isPracticeLead: result.is_practice_lead ,
    latticeId: result.lattice_id,
    name: result.name,
    slackId: result.slack_id,
  };
};

const defaultState = (): UserState => {
  const accessToken: string | null = localStorage.getItem('accessToken');
  const refreshToken: string | null = localStorage.getItem('refreshToken');

  return {
    authStatus: accessToken === null ? 'idle' : 'loaded',

    accessToken: accessToken,
    refreshToken: refreshToken,

    claims: unpackClaims(accessToken),
  };
};

export const module: Module<UserState, RootState> = {
  state: defaultState(),
  getters: {
    accessToken: (state: UserState) => state.accessToken,
    bambooId: (state: UserState) => state.claims?.bambooId ?? null,
    email: (state: UserState) => state.claims?.email ?? null,
    harvestId: (state: UserState) => state.claims?.harvestId ?? null,
    isAdmin: (state: UserState) => state.claims?.isAdmin ?? false,
    isPracticeLead: (state: UserState) => state.claims?.isPracticeLead ?? false,
    latticeId: (state: UserState) => state.claims?.latticeId ?? null,
    name: (state) => state.claims?.name ?? null,
    refreshToken: (state: UserState) => state.refreshToken,
    slackId: (state: UserState) => state.claims?.slackId,
    authStatus: (state: UserState) => state.authStatus,
  },
  mutations: {
    [UserMutations.ACCESS_TOKEN]: (state, accessToken: string) => {
      state.accessToken = accessToken;
      if (accessToken === null) {
        localStorage.removeItem('accessToken');
      } else {
        localStorage.setItem('accessToken', accessToken);
      }
    },
    [UserMutations.CLAIMS]: async (state) => {
      state.claims = unpackClaims(state.accessToken);
    },
    [UserMutations.CLEAR_STATE]: (state) => {
      Object.assign(state, defaultState());
    },
    [UserMutations.REFRESH_TOKEN]: (state, refreshToken: string) => {
      state.refreshToken = refreshToken;
      if (refreshToken === null) {
        localStorage.removeItem('refreshToken');
      } else {
        localStorage.setItem('refreshToken', refreshToken);
      }
    },
    [UserMutations.AUTH_STATE]: (state, authStatus: RequestStatus) => {
      state.authStatus = authStatus;
    },
  },
  actions: {
    [UserActions.PERFORM_AUTHORIZATION]: async ({ commit }, idToken: string): Promise<unknown> => {
      commit(UserMutations.AUTH_STATE, 'loading');
      const res = await AuthRequests.authorize(idToken);

      commit(UserMutations.ACCESS_TOKEN, res.access_token);
      commit(UserMutations.REFRESH_TOKEN, res.refresh_token);
      commit(UserMutations.CLAIMS);
      commit(UserMutations.AUTH_STATE, 'loaded');
      return res;
    },
    [UserActions.GET_USER_BY_HARVEST_ID]: async ({}, harvestId: number): Promise<User> => {
      return await BackendRequests.getUserByHarvestId(harvestId);
    },
    [UserActions.LOAD_CLAIMS]: ({ commit }) => {
      commit(UserMutations.CLAIMS);
    },
    [UserActions.LOAD_HARVEST_USER]: async ({}, harvestId: string) => {
      return await HarvestRequests.user(harvestId);
    },
    [UserActions.LOGOUT]: ({ commit }) => {
      commit(UserMutations.ACCESS_TOKEN, null);
      commit(UserMutations.REFRESH_TOKEN, null);
      commit(UserMutations.CLEAR_STATE);
    },
    [UserActions.REFRESH_AUTHORIZATION]: async ({ commit, getters }) => {
      const res = await AuthRequests.refreshAccessToken(getters.refreshToken);
      commit(UserMutations.ACCESS_TOKEN, res.access_token);
      commit(UserMutations.REFRESH_TOKEN, res.refresh_token);
      commit(UserMutations.CLAIMS);
    },
  }
};
