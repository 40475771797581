import { DateTime } from 'luxon';
import { TimeOff, TimeOffType, TimeOffDate, Employee, EmployeeAlt, TimeOffPTOAndOOO, BambooTaskIds, TimeOffAndHolidays } from '@/types/bamboo-types';
import { BackendClient } from '../backend';

interface UserCelebrations {
  id: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  hireDate: string;
}

export default class BambooRequests {
  public static async timeOffRequests(): Promise<TimeOff> {
    const res = await BackendClient.get('/time-off/requests');
    return res.data;
  }

  public static async getTimeOffRequest(timeOffId: number): Promise<TimeOff> {
    const res = await BackendClient.get('/time-off/requests/' + timeOffId.toString());
    return res.data;
  }

  public static async getTimeOffRequestsInRangeTotals(employeeId: string, startDate: DateTime, endDate: DateTime): Promise<TimeOffPTOAndOOO> {
    const params: any = {
      employee_id: employeeId,
      start_date: startDate.toISODate(),
      end_date: endDate.toISODate()
    };
    const res = await BackendClient.get('/time-off/requests/in-range', { params });
    let totals = {
      paidTimeOff: 0,
      outOfOffice: 0,
      leave: 0,
      floatingHoliday: 0,
      companyHolidays: 0
    };

    if (res.status === 200) {
      res.data.timeEntries.forEach((entry: any) => {
        if (BambooTaskIds.BAMBOO_OUT_OF_OFFICE_ID == entry.typeId) {
          totals.outOfOffice += entry.amount;
        } else if (BambooTaskIds.BAMBOO_FAMILY_LEAVE_ID == entry.typeId || BambooTaskIds.BAMBOO_GENERAL_LEAVE_ID == entry.typeId) {
          totals.leave += entry.amount;
        } else if (BambooTaskIds.BAMBOO_FLOATING_HOLIDAY_ID == entry.typeId) {
          totals.floatingHoliday += entry.amount;
        } else {
          totals.paidTimeOff += entry.amount;
        }
      });

      res.data.holidays.forEach((entry: any) => {
        totals.companyHolidays += 8;
      });
    }

    return totals;
  }

  public static async getTimeOffRequestsInRange(employeeId: string, startDate: DateTime, endDate: DateTime): Promise<TimeOffAndHolidays|null> {
    const params: any = {
      employee_id: employeeId,
      start_date: startDate.toISODate(),
      end_date: endDate.toISODate()
    };
    const res = await BackendClient.get('/time-off/requests/in-range', { params });

    if (res.status === 200) {
      return res.data;
    }

    return {
      timeEntries: [],
      holidays: []
    };
  }

  public static async updateTimeOffStatus(timeOffId: number, status: string): Promise<boolean> {
    const res = await BackendClient.post(
      '/time-off/requests/' + timeOffId.toString(),
      { new_status: status },
      { headers: { 'Content-Type': 'application/json' } },
    );
    if (res.status === 200) {
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  }

  public static async timeOffTypes(): Promise<TimeOffType[]> {
    const res = await BackendClient.get('/time_off/types');
    if (res.status === 200) {
      return res.data.types.timeOffTypes;
    }
    return [];
  }

  public static async getTimeOff(timeOffTypeId: number, note: string, dates: TimeOffDate[]) {
    const res = await BackendClient.put('/time_off/requests', {
      timeOffTypeId,
      note,
      dates,
    });
  }

  public static async celebrations(): Promise<UserCelebrations[]> {
    const res = await BackendClient.get('/celebrations');
    if (res.status === 200) {
      return res.data.celebrations;
    }
    return [];
  }

  public static async employeeDirectory(activeOnly: boolean): Promise<Employee[]> {
    const params: any = {
      active_only: activeOnly,
    };

    const res = await BackendClient.get('/bamboo-employees', { params });
    if (res.status === 200) {
      return res.data.employees;
    }
    return [];
  }

  // TODO: This function needs to be replaced with generatePhotoURL... I'm not sure why this is a Promise still
  public static async getPhoto(id: string): Promise<any> {
    return `${process.env.VUE_APP_BACKEND_URL}/bamboo-employees/photo/${id}`;
  }

  public static generatePhotoURL(id: string): string {
    return `${process.env.VUE_APP_BACKEND_URL}/bamboo-employees/photo/${id}`;
  }

  public static async coachDirectory(): Promise<EmployeeAlt[]> {
    const res = await BackendClient.get(
      '/bamboo-employees/coach',
    );
    if (res.status === 200) {
      return res.data.employees;
    }
    return [];
  }
}
