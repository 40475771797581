export enum Department {
  BusinessOperations = 'Business Operations Practice',
  ClientServices = 'Client Services',
  Delivery = 'Delivery Practice',
  Design = 'Design Practice',
  Development = 'Development Practice',
  ManagingPartner = 'Managing Partner',
  PeopleOperations = 'People Operations',
}

export enum DepartmentTab {
  BusinessOperations = 'Business Operations',
  Delivery = 'Delivery',
  Development = 'Development',
  ManagingPartner = 'Partner',
  Design = 'User Experience',
}

export enum JobTitle {
  ExecutiveAssistant = 'Executive Assistant',
  ManagingPartner = 'Managing Partner',
  TalentLead = 'Talent Lead',
  InfrastructureEngineer = 'Infrastructure Engineer',
  BusinessOperationsLead = 'Business Operations Lead',
  DeliveryLead = 'Delivery Lead',
  DeliveryPracticeLead = 'Delivery Practice Lead',
  Developer = 'Software Developer',
  DeveloperAssociate = 'Associate Software Developer',
  DeveloperPracticeCoLead = 'Development Practice Co-Lead',
  Designer = 'UX Designer',
  DesignerAssociate = 'Associate UX Designer',
  DesignerPracticeLead = 'UX Practice Lead'
}
