import { ClientProject } from '@/types/backend/client-types';
import { Department, JobTitle } from './department-types';

export enum BambooTaskIds {
  BAMBOO_PAID_TIME_OFF_ID = '84',
  BAMBOO_OUT_OF_OFFICE_ID = '89',
  BAMBOO_GENERAL_LEAVE_ID = '86',
  BAMBOO_FAMILY_LEAVE_ID = '87',
  BAMBOO_FLOATING_HOLIDAY_ID = '90',
}

export interface TimeOffRequest {
  actions: {
    approve: boolean;
    bypass: boolean;
    cancel: boolean;
    deny: boolean;
    edit: boolean;
    view: boolean;
  };
  amount: {
    amount: number;
    unit: string;
  };
  created: string;
  dates: any;
  employeeId: number;
  end: string;
  id: number;
  name: string;
  notes: any[];
  start: string;
  status: {
    lastChanged: string;
    lastChangedByUserId: number;
    status: string;
  };
  type: {
    icon: string;
    id: number;
    name: string;
  };
}

export interface TimeOff {
  balance: number;
  requests: TimeOffRequest[];
}

export interface TimeOffType {
  id: number;
  name: string;
  units: string;
  color: string;
  icon: string;
}

export interface TimeOffDate {
  ymd: string;
  amount: number;
}

export interface TimeOffPTOAndOOO {
  paidTimeOff: number;
  outOfOffice: number;
  leave: number;
  floatingHoliday: number; 
  companyHolidays: number;
}

export interface TimeOffEntry {
  entryDate: string;
  typeId: string;
  amount: number;
}

export interface HolidayEntry {
  holidayDate: string;
  name: string;
}

export interface TimeOffAndHolidays {
  timeEntries: TimeOffEntry[];
  holidays: HolidayEntry[];
}

export interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  jobTitle: string;
  workEmail: string;
  department: string;
  workPhone: string;
  mobilePhone: string;
}

export interface EmployeeAlt {
  id: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  jobTitle: JobTitle;
  workEmail: string;
  department: Department;
  coachNames: string[];
  projects: ClientProject[];
}
