import Axios, { AxiosRequestConfig } from 'axios';

const config: AxiosRequestConfig = {
  baseURL: 'https://api.forecastapp.com',
  headers: {
    'Forecast-Account-Id': 1053589,
    'Content-Type': 'application/json',
    'Accept': 'application/json, text/html',
  },
};

const ForecastClient = Axios.create(config);
export { ForecastClient };
