import { TimeTarget } from '@/types/backend/target-types';

export const PageNames = {
  Time: 'time-detail',
  Totals: 'time',
  Targets: 'targets',
  Permissions: 'permissions',
  ProjectDetails: 'project-details',
  ClientDetails: 'client-details',
  TimeOffRequest: 'time-off-request',
  NotFound: '404',
  WeekDetails: 'week',
};

export const Day = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export function getLatestTarget(timeTargets: TimeTarget[]): TimeTarget {
  // Get the latest target to display a quick view
  // https://stackoverflow.com/questions/36577205/what-is-the-elegant-way-to-get-the-latest-date-from-array-of-objects-in-client-s
  return timeTargets.reduce((a: TimeTarget, b: TimeTarget) => {
    return new Date(a.effectiveDate) > new Date(b.effectiveDate) ? a : b;
  });
}