import type * as types from './googleTypes';
import { RequestStatus } from '@/types/request-status';

// These probably aren't reactive and might need need to be if referenced by more than one component?
let apiStatus: RequestStatus = 'idle';


declare global {
  interface Window extends types._Window {}
}


export const loadGApi = new Promise<types.Google>((resolve) => {
  if (apiStatus === 'idle') {
    apiStatus = 'loading';

    const script = document.createElement('script');

    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.addEventListener('load', () => {
      apiStatus = 'loaded';
      resolve(window.google);
    });

    document.head.appendChild(script);
  }
});

/**
 * A wrapper function which makes sure the Google client liibrary is loaded and then give access to the SDK api
 * @param action A function to execute some actions only after Google client library is loaded
 */
export function googleSdkLoaded(action: (google: types.Google) => void) {
  if (apiStatus === 'loaded') {
    action(window.google);
  } else {
    loadGApi.then((google) => {
      action(google);
    });
  }
}

export function onMount (
  idConfiguration: types.IdConfiguration,
  buttonConfig: types.ButtonConfig,
  authenticationError: Function | null,
  buttonRef: HTMLElement,
) {
  if (!idConfiguration.client_id) {
    throw new Error(
      'Prop client id required since plugin is not initialized with a client id'
    );
  }
  googleSdkLoaded((google) => {
    if (authenticationError) {
      // Patch in an error callback flow if initialization fails
      const callback = idConfiguration.callback;
      idConfiguration.callback = ((response) => {
        if (!response.credential) {
          authenticationError(response);
        } else {
          if (callback) {
            callback(response);
          }
        }
      }) as types.CredentialCallback;
    }
    google.accounts.id.initialize(idConfiguration);
    if (buttonRef) {
      google.accounts.id.renderButton(buttonRef, buttonConfig);
    }
  });
}