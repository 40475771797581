import Vue from 'vue';
import Vuex from 'vuex';
import HarvestRequests from '@/network/harvest/requests';
import ForecastRequests from '@/network/forecast/requests';
import {
  ForecastProject,
  ForecastRemainingBudgetedHours,
} from '@/types/forecast-types';
import { Project, Expense, Client } from '@/types/harvest-types';
import { Report } from '@/types/backend/big-query-types';
import { module as userState } from '@/store/modules/user';

Vue.use(Vuex);

export const enum BaseStoreActions {
  LOAD_ADMIN_DATA = 'LOAD_ADMIN_DATA',
}

export const enum BaseStoreMutations {
  PROJECT_OVERVIEWS_ACTIVE = 'PROJECT_OVERVIEWS_ACTIVE',
  PROJECTS_OVERVIEWS_ARCHIVED = 'PROJECTS_OVERVIEWS_ARCHIVED',
  FORECAST_PROJECTS = 'FORECAST_PROJECTS',
  HARVEST_PROJECTS = 'HARVEST_PROJECTS',
  EXPENSES = 'EXPENSES',
  BUDGET_HOURS = 'BUDGET_HOURS',
  LOAD_ADMIN_DATA_FAILED = 'GET_ADMIN_DATA_FAILED',
  CHECK_LIST_INTERVAL_ID = 'CHECK_LIST_INTERVAL_ID',
  ANALYTIC_REPORTS = 'ANALYTIC_REPORTS',
}

export interface RootState {
  forecastProjects: ForecastProject[];
  harvestProjects: Project[];
  expenses: Expense[];
  budgetHours: ForecastRemainingBudgetedHours[];
  loadAdminDataFailed: boolean;
  checkListIntervalId: number | null;
  analyticReports: Report[];
}

export default new Vuex.Store<RootState>({
  state: {
    forecastProjects: [],
    harvestProjects: [],
    expenses: [],
    budgetHours: [],
    loadAdminDataFailed: false,
    checkListIntervalId: null,
    analyticReports: [],
  },
  mutations: {
    // TODO transition all these over to the new style...
    //  A few of these are making their own network calls when that should all be done in this file
    //  (or ideally a module inside the /store/modules directory)
    forecastProjects: (state, value: ForecastProject[]) => {
      if (!!value) {
        state.forecastProjects = [...value];
      }
    },
    harvestProjects: (state, value: Project[]) => {
      if (!!value) {
        state.harvestProjects = [...value];
      }
    },
    expenses: (state, value: Expense[]) => {
      if (!!value) {
        state.expenses = [...value];
      }
    },
    budgetHours: (state, value: ForecastRemainingBudgetedHours[]) => {
      if (!!value) {
        state.budgetHours = [...value];
      }
    },
    [BaseStoreMutations.LOAD_ADMIN_DATA_FAILED]: (state, value: boolean) => {
      state.loadAdminDataFailed = value;
    },
    checkListIntervalId: (state, value: number) => {
      state.checkListIntervalId = value;
    },
    analyticReports: (state, value: Report[]) => {
      if (!!value) {
        state.analyticReports = [...value];
      }
    },
  },
  actions: {
    [BaseStoreActions.LOAD_ADMIN_DATA]: async ({ commit }) => {
      commit(BaseStoreMutations.LOAD_ADMIN_DATA_FAILED, false);

      const harvestProjects = HarvestRequests.getAllProjects();
      const forecastProjects = ForecastRequests.projects();
      const harvestExpenses = HarvestRequests.getExpenses();
      const forecastBudgetHours = ForecastRequests.remainingBudgetHours();
      const clients = HarvestRequests.clients();
      await Promise.all([
        harvestProjects,
        forecastProjects,
        harvestExpenses,
        forecastBudgetHours,
        clients,
      ]).then((values) => {
        commit('harvestProjects', values[0]);
        const projects = values[1];
        const timeOff = projects.findIndex(
          (project) => project.name === 'Time Off',
        );
        projects.splice(timeOff, 1);
        commit('forecastProjects', projects);
        commit('expenses', values[2]);
        commit('budgetHours', values[3]);
        commit('clients', values[4]);
      }).catch(() => {
        commit(BaseStoreMutations.LOAD_ADMIN_DATA_FAILED, true);
      });
    },
  },
  modules: {
    user: userState,
  },
});
